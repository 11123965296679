import {Component} from '@angular/core'
import {
  COUNSELLING_ROUTE_PATH,
  CUSTOMER_ROUTE_PATH,
  PORTFOLIO_ROUTE_PATH
} from '../../application/data-types'
import {ConfigService} from '../../services/config.service'
import { RouterLink } from '@angular/router';
import { AbasecStatusComponent } from '../abasec-status/abasec-status.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';


@Component({
    selector: 'spb-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [RouterLink, AbasecStatusComponent, MatTooltip, MatButton, MatMenuTrigger, MatIcon, MatMenu, MatMenuItem, AsyncPipe]
})
export class HeaderComponent {
  protected readonly Boolean = Boolean

  public customerPath = '/' + CUSTOMER_ROUTE_PATH
  public portfolioPath = '/' + PORTFOLIO_ROUTE_PATH
  public counsellingPath = '/' + COUNSELLING_ROUTE_PATH

  /**
   * The constructor
   * @param configService - Holds the logged in state and tells ut what to show.
   */
  constructor(
    public configService: ConfigService
  ) {
  }
}
