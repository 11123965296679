<div>
  <button (click)="check$.next(null)" mat-button>
    <span [ngClass]="status" class="content">
    @switch (status) {
      @case ('up') {
        <span>AbaSec&nbsp;<span *ngTemplateOutlet="dur"></span>
        <mat-icon>check_circle</mat-icon>
      </span>
      }
      @case ('down') {
        <span> AbaSec&nbsp;<span *ngTemplateOutlet="dur"></span>
        <mat-icon>mood_bad</mat-icon>
      </span>
      }
      @case ('unknown') {
        <span>AbaKnas&nbsp;<mat-icon>help_outline</mat-icon>
      </span>
      }
    }
    </span>
  </button>
</div>
<ng-template #dur><span class="duration">({{ duration }})&nbsp;</span></ng-template>

