import {inject} from '@angular/core'
import {ActivatedRouteSnapshot, Router} from '@angular/router'
import {Observable, of} from 'rxjs'
import {map, switchMap} from 'rxjs/operators'
import {Customer, CustomerService} from '../services/customer.service'

export const customerGuard = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const service = inject(CustomerService)
  const router = inject(Router)
  return service.currentCustomer$.pipe(
    switchMap((customer: Customer | null) => {
      // If we have a customer loaded proceed.
      if (customer) {
        return of(true)
      }
      if (route.params.id) {
        // Happens to return a Customer, but we do not care.
        return service.getCustomer(route.params.id)
      }
      // If no customer and no id go back to customers, is a promise but who cares.
      return router.navigate(['/customers'])
    }),
    // At the end we always return true.
    map(() => true)
  )

}
