import {Component} from '@angular/core'
import {Customer, CustomerService} from '../../services/customer.service'
import {MorningstarAccount, MorningstarService} from '../../services/morningstar.service'
import {filter, first, switchMap, tap} from 'rxjs/operators'
import {MatButton} from '@angular/material/button'
import {RouterLink} from '@angular/router'
import {MatIcon} from '@angular/material/icon'
import {AsyncPipe} from '@angular/common'

@Component({
  selector: 'spb-customer-action',
  templateUrl: './customer-action.component.html',
  styleUrls: ['./customer-action.component.scss'],
  standalone: true,
  imports: [MatButton, RouterLink, MatIcon, AsyncPipe]
})
export class CustomerActionComponent {

  public saving = false

  constructor(
    public customerService: CustomerService,
    private morningStarService: MorningstarService
  ) {
  }


  public save(): void {
    this.saving = true
    const accounts: MorningstarAccount[] = this.morningStarService.getAccounts()
    const answers = this.morningStarService.getAnswers()
    this.customerService.currentCustomer$.pipe(
      filter(Boolean),
      first(),
      switchMap((customer: Customer) => {
        customer.morningstar.accounts = accounts.filter(a => !a.abasec)
        // We must not save checked state
        customer.morningstar.accounts.forEach(a => delete a.checked)
        customer.morningstar.answers = answers
        return this.customerService.saveCustomer(customer)
      }),
      tap(() => {
        setTimeout(() => this.saving = false, 1000)
      })
    ).subscribe()
  }
}
