{
  "name": "ir-app",
  "version": "2.5.1-890cf027.0",
  "scripts": {
    "install": "cp ./package.json ./src/assets",
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.10",
    "@angular/common": "^18.2.10",
    "@angular/compiler": "^18.2.10",
    "@angular/core": "^18.2.10",
    "@angular/forms": "^18.2.10",
    "@angular/platform-browser": "^18.2.10",
    "@angular/platform-browser-dynamic": "^18.2.10",
    "@angular/router": "^18.2.10",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.11",
    "@angular-eslint/builder": "18.4.0",
    "@angular-eslint/eslint-plugin": "18.4.0",
    "@angular-eslint/eslint-plugin-template": "18.4.0",
    "@angular-eslint/schematics": "18.4.0",
    "@angular-eslint/template-parser": "18.4.0",
    "@angular/cdk": "^18.2.11",
    "@angular/cli": "^18.2.11",
    "@angular/compiler-cli": "^18.2.10",
    "@angular/material": "^18.2.11",
    "@sparbanken-syd/personnummer": "^3.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^18.2.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^18.1.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^18.0.0",
    "@types/jasmine": "~5.1.4",
    "@types/node": "^20.11.26",
    "@types/sparbanken-syd-auth-backend": "^2.1.0",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "2.29.1",
    "eslint-plugin-jsdoc": "^48.2.1",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-spec-reporter": "^0.0.36",
    "postcss": "^8.4.33",
    "postcss-scss": "^4.0.6",
    "stylelint": "^16.2.1",
    "stylelint-config-standard-scss": "^13.0.0",
    "stylelint-scss": "^6.1.0",
    "typescript": "~5.5.4"
  }
}
