import {Component} from '@angular/core'
import {environment} from '../../../environments/environment'
import { TestPdfComponent } from '../../common/test-pdf/test-pdf.component';
import { NamespaceComponent } from '../namespace/namespace.component';
import { ErrorDisplayComponent } from '../../common/error-display/error-display.component';
import { CustomerActionComponent } from '../customer-action/customer-action.component';
import { MorningstarComponent } from '../morningstar/morningstar.component';

@Component({
    selector: 'spb-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [TestPdfComponent, NamespaceComponent, ErrorDisplayComponent, CustomerActionComponent, MorningstarComponent]
})
export class HomeComponent {
  protected readonly environment = environment
}
