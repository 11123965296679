<div class="floater">
  <mat-expansion-panel expanded="false">
    <mat-expansion-panel-header>Testa signering</mat-expansion-panel-header>
    <form [formGroup]="form">
      <mat-form-field>
        <mat-label>ID</mat-label>
        <input formControlName="id" matInput>
      </mat-form-field>
      <mat-form-field>
        <mat-select (selectionChange)="setStatus($event)"
                    formControlName="status"
                    ngDefaultControl placeholder="Välj status">
          <mat-option value="MISSING">MISSING</mat-option>
          <mat-option value="OK">OK</mat-option>
          <mat-option value="TIMEOUT">TIMEOUT</mat-option>
          <mat-option value="ERROR">ERROR</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <button (click)="open()" mat-raised-button>Öppna</button>
  </mat-expansion-panel>
</div>
