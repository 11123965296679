@if (configService.errors$ | async; as error) {
  <div class="error">
    {{ error }}
    <button
      (click)="configService.errors$.next(null)"
      mat-icon-button>
      <mat-icon color="warn">delete_outline</mat-icon>
    </button>
  </div>
}
