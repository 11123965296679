<div class="spb-holder">
  <spb-header></spb-header>
  <div class="spb-plain-content">
    <router-outlet></router-outlet>
  </div>
  <div class="spb-filler"></div>
  <spb-footer
    (logoutUser)="configService.logout()"
    [token]="configService.accessToken$()"
    [license]="true"
    [version]="version"
    copyrightYears="2023-2024"
  ></spb-footer>
</div>
<router-outlet name="print"></router-outlet>