import {registerLocaleData} from '@angular/common'
import {provideHttpClient, withInterceptors} from '@angular/common/http'
import localeFrExtra from '@angular/common/locales/extra/fr'
import localeFr from '@angular/common/locales/fr'
import {APP_INITIALIZER, ApplicationConfig, LOCALE_ID, provideZoneChangeDetection} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async'
import {provideRouter, withHashLocation, withInMemoryScrolling} from '@angular/router'

import {routes} from './app.routes'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'

import {ConfigService} from './services/config.service'

import {WINDOW} from './application/data-types'

registerLocaleData(localeFr, 'fr', localeFrExtra)

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (s: ConfigService) => () => s.bootstrap(),
      deps: [ConfigService],
      multi: true
    },
    {provide: WINDOW, useValue: window},
    {provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: LOCALE_ID, useValue: 'fr'},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {floatLabel: 'always', hideRequiredMarker: true, appearance: 'outline'}
    },
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes, withHashLocation(), withInMemoryScrolling({anchorScrolling: 'enabled'})),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        responseInterceptor
      ])
    )
  ]
}