import {Component, inject} from '@angular/core'
import {PortfolioService} from '../../services/portfolio.service'
import {Router} from '@angular/router'
import {MatDialog} from '@angular/material/dialog'
import {WaitDialogComponent} from '../../common/wait-dialog/wait-dialog.component'
import {MatButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'


import {FILE_READER} from '../../application/data-types'

@Component({
  selector: 'spb-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
  standalone: true,
  imports: [MatButton, MatIcon],
  providers: [{
    provide: FILE_READER,
    useClass: FileReader
  }]
})
export class ImportComponent {

  private readonly fileReader: FileReader = inject(FILE_READER)
  private readonly portfolioService = inject(PortfolioService)
  private router = inject(Router)
  private dialog = inject(MatDialog)

  onFileSelected(event: EventTarget | null): any {
    const selectedFile = (event as HTMLInputElement).files![0]
    this.fileReader.readAsDataURL(selectedFile)
    this.fileReader.onloadend = () => {
      const uploadedFile = this.fileReader.result
      const ref = this.dialog.open(WaitDialogComponent, {
        disableClose: true,
        data: {
          title: 'Importerar...',
          message: ['Importerar, det tar ca 20 sekunder.']
        }
      })
      this.portfolioService.importFile(uploadedFile as string).subscribe({
        next: () => {
          ref.close()
          this.router.navigate(['portfolios', 'home']).then()
        }
      })
    }
  }
}
