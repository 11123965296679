import {Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'
import {
  COUNSELLING_ROUTE_PATH,
  CUSTOMER_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  MORNINGSTAR_ROUTE_PATH,
  PORTFOLIO_ROUTE_PATH
} from './application/data-types'
import morningstarRoutes from './morningstar/morningstar.routes'
import portfoliosRoutes from './portfolios/portfolios.routes'

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () => import('./login/login/login.component').then(m => m.LoginComponent),
    canActivate: [authGuard]
  },
  {
    path: CUSTOMER_ROUTE_PATH,
    loadComponent: () => import('./customers/home/home.component').then(m => m.HomeComponent),
    canActivate: [authGuard]
  },
  {
    path: PORTFOLIO_ROUTE_PATH,
    children: portfoliosRoutes,
    canActivate: [authGuard]
  },
  {
    path: MORNINGSTAR_ROUTE_PATH,
    children: morningstarRoutes,
    canActivate: [authGuard]
  },
  {
    path: LOGIN_ROUTE_PATH,
    loadComponent: () => import('./login/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: COUNSELLING_ROUTE_PATH,
    loadComponent: () => import('./counselling/start/start.component').then(m => m.StartComponent),
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
]
