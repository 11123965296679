import {Routes} from '@angular/router'
import {HomeComponent} from './home/home.component'
import {customerGuard} from '../application/customer.guard'


export default [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'home',
    component: HomeComponent,
    children: [
      {
        path: ':id',
        component: HomeComponent,
        canActivate: [customerGuard]
      }
    ]
  }
] satisfies Routes
