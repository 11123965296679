<h3>Modellportföljer</h3>

<!-- Only Portfolio Managers can edit portfolios -->
@if (configService.isPortfolioManager$()) {
  <div>
    <button (click)="portfolioService.currentPortfolio$.next(template)"
            color="primary"
            mat-raised-button>Skapa portfölj
    </button>
    <button [routerLink]="['/portfolios/import']"
            color="accent"
            mat-stroked-button>Importera
      <mat-icon>upload_outline</mat-icon>
    </button>
  </div>
}
<div class="holder">
  <spb-portfolio-list></spb-portfolio-list>
  <div class="spb-filler"></div>
  <spb-portfolio></spb-portfolio>
  <div class="spb-filler"></div>
</div>
