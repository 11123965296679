import {AfterViewInit, Component, inject, OnInit, ViewChild} from '@angular/core'
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table'
import {ConfigService} from '../../services/config.service'
import {ModelPortfolio, PortfolioService} from '../../services/portfolio.service'
import {filter, first, switchMap} from 'rxjs/operators'
import {MatSort, MatSortHeader} from '@angular/material/sort'
import {MatDialog} from '@angular/material/dialog'
import {CommonDeleteDialogComponent} from '../../common/common-delete-dialog/common-delete-dialog.component'
import {MatIcon} from '@angular/material/icon'

@Component({
  selector: 'spb-portfolio-list',
  templateUrl: './portfolio-list.component.html',
  styleUrls: ['./portfolio-list.component.scss'],
  standalone: true,
  imports: [MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, MatIcon, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow]
})
export class PortfolioListComponent implements OnInit, AfterViewInit {

  /**
   * The boilerplate sorter.
   */
  @ViewChild(MatSort, {static: true}) sort: MatSort = {} as any

  /**
   * Boilerplate for material tables
   */
  public displayedColumns: string[] = ['name', 'copy', 'delete']

  /**
   * Bla bla table source.
   */
  public dataSource: MatTableDataSource<ModelPortfolio> = new MatTableDataSource<ModelPortfolio>([])

  private readonly dialog = inject(MatDialog)
  private readonly configService = inject(ConfigService)
  public readonly portfolioService = inject(PortfolioService)

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort
  }

  public ngOnInit(): void {
    // If user logged doesn't have "portfolio manager" role it cannot copy or
    // delete portfolios, they will only be able to see them
    if (!this.configService.isPortfolioManager$()) {
      this.displayedColumns = ['name']
    } else {
      this.displayedColumns = ['name', 'copy', 'delete']
    }

    this.portfolioService.getPortfolios().subscribe()
    this.portfolioService.portfolios$.subscribe({
      next: (portfolios: ModelPortfolio[]) => {
        this.dataSource.data = portfolios
        }
      }
    )
  }

  public delete(id: string): void {
    const whatToDelete: ModelPortfolio | undefined = this.dataSource.data
      .find((p: ModelPortfolio) => p.id === id)

    if (whatToDelete?.name) {
      this.dialog
        .open(CommonDeleteDialogComponent, {data: whatToDelete.name})
        .afterClosed().pipe(
        filter(Boolean),
        switchMap(() => this.portfolioService.deletePortfolio(id))
      ).subscribe()
    }
  }

  public copy(id: string): void {
    this.portfolioService.portfolios$.pipe(
      first()
    ).subscribe({
      next: (portfolios: ModelPortfolio[] | null) => {
        const portfolio = portfolios!.find((p: ModelPortfolio) => p.id === id)
        const newPortfolio = JSON.parse(JSON.stringify(portfolio))
        newPortfolio.id = ''
        this.portfolioService.currentPortfolio$.next(newPortfolio)
      }
    })
  }
}
