import {Routes} from '@angular/router'
import {HomeComponent} from './home/home.component'
import {ImportComponent} from './import/import.component'

export default [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'import',
    component: ImportComponent
  }
] satisfies Routes

