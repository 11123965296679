import {Component, OnDestroy, OnInit} from '@angular/core'
import {MorningstarService, NAMESPACE_CORPORATE} from '../../services/morningstar.service'
import {Subscription} from 'rxjs'

@Component({
    selector: 'spb-namespace',
    templateUrl: './namespace.component.html',
    styleUrls: ['./namespace.component.scss'],
    standalone: true
})
export class NamespaceComponent implements OnInit, OnDestroy {

  public namespace = 'Privat'

  private namespace$ = new Subscription()

  constructor(
    public morningstarService: MorningstarService
  ) {
  }

  public ngOnInit(): void {
    this.namespace$ = this.morningstarService.namespace$.subscribe({
      next: (namespace: string) => this.namespace = namespace === NAMESPACE_CORPORATE ? 'Företag' : 'Privat'
    })
  }

  public ngOnDestroy(): void {
    this.namespace$.unsubscribe()
  }
}
