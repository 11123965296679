<div class="holder">
  <h3>Modellportföljer</h3>
  <p>Här finns det möjlighet att ladda upp en xls(x)-fil med portföljer. Om vädret är bra och filen ser ut som
    beskrivet nedan så kommer det gå fint.</p>
  <p>Tryck på knappen nedan men se till att du läst instruktionen om hur filen skall se ut först.</p>
  <button (click)="fileUpload.click()" class="upload-btn" color="accent"
          mat-stroked-button>Välj fil
    <mat-icon>attach_file</mat-icon>
  </button>
  <h2>Instruktioner (läs dem)</h2>
  <ul>
    <li>Det måste vara en Excel-fil som innehåller flikar</li>
    <li>Namnet på fliken anger modellportföljens namn</li>
    <li>Vi jämför på namnet <em>exakt</em> (inklusive mellanslag å sånt) för att se om vi skall ersätta en portfölj
      eller skapa en ny.
    </li>
    <li>I varje flik importeras de sex första kolumnerna (A, B, C, D, E och F)</li>
    <li>Den första raden i varje flik måste ha: A1 = '', B1 = 'ISIN', C1 = 'Portfolio Weighting %', D1 = 'Ongoing
      cost', E1 = 'Transaction cost', F1 = 'Kickback %' (A1 skall alltså vara tom)
    </li>
    <li>Varje rad (2 och nedåt) i varje flik innehåller sedan namnet på innehavet (A), ISIN-nummer (B), fördelning i
      procent (C), Årlig avgift (D), Transaktions kostnad (E) och Distributörsersättning i % (F).
    </li>
    <li>Se den bifogade bilden nedan.</li>
    <li>Filen får inte vara större än ca 6 MB</li>
  </ul>
  <img alt="Exempel på XLS" src="./assets/example.png">
  <input #fileUpload (change)="onFileSelected($event.target)" class="file-input" type="file"/>
</div>
