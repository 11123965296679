@if (customerService.currentCustomer$ | async;as customer) {
  <div class="holder">
    <div>
      {{ customer.firstName }} {{ customer.lastName }}
    </div>
    @if (false) {
      <button [routerLink]="['', {outlets: {print: ['print']}}]" [skipLocationChange]="true"
              color="accent" mat-stroked-button>
        <mat-icon fontIcon="print"></mat-icon>
        Skriv ut
      </button>
    }
    <button (click)="save()" [disabled]="saving" color="primary" mat-raised-button>Spara</button>
  </div>
}
