import {Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core'
import {
  MorningstarAccount,
  MorningstarReportResult,
  MorningstarService,
  MorningStartLoginResult,
  NAMESPACE_CORPORATE,
  NAMESPACE_RETAIL
} from '../../services/morningstar.service'
import {ModelPortfolio, PortfolioService} from '../../services/portfolio.service'
import {filter, first, switchMap, tap} from 'rxjs/operators'
import {Customer, CustomerService} from '../../services/customer.service'
import {environment} from '../../../environments/environment'
import {from, Observable, Subscription} from 'rxjs'
import {ConfigService} from '../../services/config.service'
import {SparbankenUser} from '@sparbanken-syd/sparbanken-syd-bankid'
import {AsyncPipe, DOCUMENT, NgClass, NgIf} from '@angular/common'
import {MatProgressBar} from '@angular/material/progress-bar'
import {RouterLink} from '@angular/router'

/**
 * This is so that we have a "global" to trick Typescript with.
 * It is apparently attached globally by the Morningstar stuff
 */
declare const morningstar: any

/**
 * 'Universes seems to be fairly constant. Totally undocumented shit
 * from Morningstar.
 */
const universes = {
  universes: [
    {
      value: 'FOSWE$$ALL_4403',
      labelKey: 'FOSWE$$ALL_4403'
    },
    {
      value: 'FONORP$ALL',
      labelKey: 'FONORP$ALL'
    }
  ]
}

/**
 * More magix from Morningstar.
 *
 */
const appConfigTpl = {
  settings: {
    // urlKey: '5dy6yjixaa',
    languageId: 'sv-SE',
    currencyId: 'SEK',
    environment: 'prod', // environment.morningstarEnv,
    tokenSource: 'uim'
  },
  components: {
    mwcPortfolioPlanner: {
      type: 'mwcPpPortfolioPlanner',
      settings: {
        pdfReports: {
          components: {
            signOff: {
              type: 'signOffComponent',
              components: {
                simpleContainer: {
                  type: 'simpleContainer',
                  settings: {
                    border: 0,
                    tables: {
                      tableTitle: {
                        rows: {
                          row1: {
                            column1: {
                              padding: [10, 0, 0, 0],
                              componentId: 'agreementLabel'
                            }
                          },
                          row2: {
                            column1: {
                              padding: [10, 0, 0, 0],
                              componentId: 'agreementDesc1'
                            }
                          },
                          row3: {
                            column1: {
                              padding: [5, 0, 0, 0],
                              componentId: 'agreementDesc2'
                            }
                          },
                          row4: {
                            column1: {
                              padding: [5, 0, 0, 0],
                              componentId: 'agreementDesc3'
                            }
                          },
                          row5: {
                            column1: {
                              padding: [5, 0, 0, 0],
                              componentId: 'agreementDesc4'
                            }
                          }
                        }
                      },
                      tableSubTitle: {
                        numberColumns: 3,
                        columnWidths: '6|1|6',
                        rows: {
                          rowTitle: {
                            column1: {
                              padding: [16, 0, 0, 0],
                              componentId: 'agreementDesc5'
                            },
                            column2: {
                              componentId: ''
                            },
                            column3: {
                              padding: [16, 0, 0, 0],
                              componentId: 'agreementDesc6'
                            }
                          },
                          rowLine: {
                            column1: {
                              padding: [36, 0, 0, 0],
                              componentId: 'horizontalLine'
                            },
                            column2: {
                              componentId: ''
                            },
                            column3: {
                              padding: [36, 0, 0, 0],
                              componentId: 'horizontalLine'
                            }
                          }
                        }
                      },
                      tableSign: {
                        numberColumns: 2,
                        columnWidths: '6|4',
                        rows: {
                          row1: {
                            column1: {
                              colspan: 2,
                              padding: [32, 0, 0, 0],
                              componentId: ''
                            }
                          },
                          row2: {
                            column1: {
                              componentId: 'clientSignatureTitle'
                            },
                            column2: {
                              componentId: 'dateTitle'
                            }
                          },
                          row3: {
                            column1: {
                              colspan: 2,
                              padding: [32, 0, 0, 0],
                              componentId: ''
                            }
                          },
                          row4: {
                            column1: {
                              componentId: 'advisorSignatureTitle'
                            },
                            column2: {
                              componentId: 'dateTitle'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            },
            riskProfile: {
              type: 'riskProfileComponent',
              components: {
                simpleContainer: {
                  type: 'simpleContainer',
                  settings: {
                    tables: {
                      table: {
                        rows: {
                          row1: {
                            column1: {
                              componentId: 'riskProfileTitle'
                            }
                          },
                          row2: {
                            column1: {
                              padding: [10, 0, 0, 0],
                              componentId: 'riskProfileTitleDescription'
                            }
                          },
                          row3: {
                            column1: {
                              padding: [10, 0, 0, 0],
                              componentId: 'riskProfileLargeDescription'
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        clientId: 'PlannerMockClient',
        universes: universes,
        clientAccounts: [] as MorningstarAccount[], // clientAccounts,
        modelPortfolios: [] as ModelPortfolio[], //modelPortfolios,
        reportResults: {} as MorningstarReportResult
      }
    }
  }
}

/**
 * This is the more or less constant loader config. Note dependency
 * to "appConfig"
 */
const loaderConfigTpl = {
  lazy: {
    lazyLoadComponent: true,
    bundlesToDownload: {
      mwcPpPortfolioPlanner: true
    }
  },
  configuration: {
    source: {
      type: 'config-api'
    },
    // Namespace is a alias used by Morningstar configuration service to access client specific configuration.
    namespace: 'sparbanken.OPP558951.mwc-planner.corporate',
    // Overrides has priority over settings available in client specific configuration store on configuration service.
    //overrides: appConfig,
    environment: environment.morningstarEnv // uat -> Use for QA, prod -> Use in your live enviornment.
  },
  component: {
    source: {
      environment: 'prod'
    }
  },
  // Morningstar design system(MDS) specifies the standards for Morningstar business components.
  css: {
    mds: {
      style: true,
      icons: true,
      version: '2.37.0'
    },
    customStyles: ['./assets/client.css']
  },
  apiTokens: {
    // Please replace token below with MaaS token retrieved by doing POST call to Morningstar as a service authentication service.
    apiGatewayToken: ''
  }
}

const retailText = `<div>
<h3 style="margin-top: 70px; margin-bottom: 0; font-family: Univers,HelveticaNeue,Helvetica Neue,Helvetica,Arial,sans-serif">
   Hållbarhetspreferenser i investeringsrådgivning</h3>
  <hr>
  <ul> Som kund i Sparbanken Syd bidrar du till att banken och vår stiftelse kan utveckla samhället och bygden där vi verkar och bor.</ul>
  <ul> Pengar innebär makt att påverka och som investerare i fonder blir du delägare i, eller långivare till en mängd olika företag. För att vi ska kunna ge dig passande investeringsråd är det därför avgörande att vi förstår hur du tänker kring hållbara investeringar, ifall du har specifika hållbarhetspreferenser och om det finns vissa branscher du inte vill att dina sparpengar investeras i. </ul>
  <ul><i>Vi vill göra dig uppmärksam på att snäva eller väldigt specifika krav kring hållbarhet kan göra det svårare eller ibland omöjligt att hitta en passande sparprodukt. Om så är fallet kommer vi upplysa dig om detta.</i></ul>
  <ul><b>Information om hållbarhetspreferenser</b></ul>
  <ul>I syfte att harmonisera och påskynda hållbarhetsarbetet i Europa har EU beslutat om flera gemensamma regelverk. Man har också definierat ett specifikt begrepp kallat ”hållbarhetspreferenser”.</ul>
  <ul>Enligt regelverket kan du som sparare ha hållbarhetspreferenser inom tre olika områden: </ul>
  <ul><b>Du kan uttrycka en preferens som innebär att din investering ska inkludera en lägsta andel Taxonomiförenlig verksamhet.</b> Med detta menas verksamheter som definierats i EU:s taxonomiförordning och där verksamheten ska bidra väsentligt till något av de nedan 6 fastställda målen. För att klassas som hållbar får verksamheten inte heller orsaka betydande skada på något av de övriga målen.</ul>
  <ul>1. Begränsning av klimatförändringar.</ul>
  <ul>2. Anpassning till klimatförändringar.</ul>
  <ul>3. Hållbar användning och skydd av vatten samt marina resurser.</ul>
  <ul>4. Övergång till en cirkulär ekonomi.</ul>
  <ul>5. Förebyggande och begränsning av miljöföroreningar.</ul>
  <ul>6. Skydd och återställande av biologisk mångfald och ekosystem.</ul>
  <ul><b>Du kan också uttrycka en preferens att din investering ska inkludera en lägsta andel hållbara investeringar enligt EU:s förordning om hållbarhetsrelaterade upplysningar (SFDR).</b> Detta är en reglering där fondbolag självständigt ska göra en bedömning ifall deras fonder kan anses hållbara utifrån påverkan på miljö samt sociala faktorer. Inom SFDR klassificeras finansiella produkter efter om de har hållbara investeringar som mål eller om de främjar miljörelaterade eller sociala egenskaper.</ul>
  <ul><b>Du kan uttrycka en preferens att din investering ska ta hänsyn till och på så vis undvika huvudsakliga negativa konsekvenser</b> för hållbarhetsfaktorer såsom miljö, klimat, samhälle och mänskliga rättigheter (Principal Adverse Impacts, PAI).</ul>
  <ul>Det finns 5 olika huvudområden där fonder kan välja att arbeta för att undvika negativa konsekvenser:</ul>
  <ul>1. Växthusutsläpp</ul>
  <ul>2. Biologisk mångfald</ul>
  <ul>3. Vatten</ul>
  <ul>4. Avfall</ul>
  <ul>5. Sociala förhållanden och personalfrågor</ul>
</div>`
const corporateText = `<div>
<h3 style="margin-top: 70px; margin-bottom: 0; font-family: Univers,HelveticaNeue,Helvetica Neue,Helvetica,Arial,sans-serif">
Hållbarhetspreferenser i investeringsrådgivning</h3>
<hr>
  <ul> Som kund i Sparbanken Syd bidrar du till att banken och vår stiftelse kan utveckla samhället och bygden där vi verkar och bor.</ul>
  <ul> Pengar innebär makt att påverka och som investerare i fonder blir du delägare i, eller långivare till en mängd olika företag. För att vi ska kunna ge dig passande investeringsråd är det därför avgörande att vi förstår hur du tänker kring hållbara investeringar, ifall du har specifika hållbarhetspreferenser och om det finns vissa branscher du inte vill att företagets sparpengar investeras i. </ul>
  <ul><i>Vi vill göra dig uppmärksam på att snäva eller väldigt specifika krav kring hållbarhet kan göra det svårare eller ibland omöjligt att hitta en passande sparprodukt. Om så är fallet kommer vi upplysa dig om detta.</i></ul>
  <ul><b>Information om hållbarhetspreferenser</b></ul>
  <ul>I syfte att harmonisera och påskynda hållbarhetsarbetet i Europa har EU beslutat om flera gemensamma regelverk. Man har också definierat ett specifikt begrepp kallat ”hållbarhetspreferenser”.</ul>
  <ul>Enligt regelverket kan du som sparare ha hållbarhetspreferenser inom tre olika områden: </ul>
  <ul><b>Du kan uttrycka en preferens som innebär att din investering ska inkludera en lägsta andel Taxonomiförenlig verksamhet.</b> Med detta menas verksamheter som definierats i EU:s taxonomiförordning och där verksamheten ska bidra väsentligt till något av de nedan 6 fastställda målen. För att klassas som hållbar får verksamheten inte heller orsaka betydande skada på något av de övriga målen.</ul>
  <ul>1. Begränsning av klimatförändringar.</ul>
  <ul>2. Anpassning till klimatförändringar.</ul>
  <ul>3. Hållbar användning och skydd av vatten samt marina resurser.</ul>
  <ul>4. Övergång till en cirkulär ekonomi.</ul>
  <ul>5. Förebyggande och begränsning av miljöföroreningar.</ul>
  <ul>6. Skydd och återställande av biologisk mångfald och ekosystem.</ul>
  <ul><b>Du kan också uttrycka en preferens att din investering ska inkludera en lägsta andel hållbara investeringar enligt EU:s förordning om hållbarhetsrelaterade upplysningar (SFDR).</b> Detta är en reglering där fondbolag självständigt ska göra en bedömning ifall deras fonder kan anses hållbara utifrån påverkan på miljö samt sociala faktorer. Inom SFDR klassificeras finansiella produkter efter om de har hållbara investeringar som mål eller om de främjar miljörelaterade eller sociala egenskaper.</ul>
  <ul><b>Du kan uttrycka en preferens att din investering ska ta hänsyn till och på så vis undvika huvudsakliga negativa konsekvenser</b> för hållbarhetsfaktorer såsom miljö, klimat, samhälle och mänskliga rättigheter (Principal Adverse Impacts, PAI).</ul>
  <ul>Det finns 5 olika huvudområden där fonder kan välja att arbeta för att undvika negativa konsekvenser:</ul>
  <ul>1. Växthusutsläpp</ul>
  <ul>2. Biologisk mångfald</ul>
  <ul>3. Vatten</ul>
  <ul>4. Avfall</ul>
  <ul>5. Sociala förhållanden och personalfrågor</ul>
</div>`

@Component({
  selector: 'spb-morningstar',
  templateUrl: './morningstar.component.html',
  styleUrls: ['./morningstar.component.scss'],
  standalone: true,
  imports: [NgIf, MatProgressBar, RouterLink, NgClass, AsyncPipe]
})
export class MorningstarComponent implements OnInit, OnDestroy {

  /**
   * Let us keep a reference to this one...
   */
  @ViewChild('planner', {static: true}) parent: ElementRef = {} as any
  public loading = true
  /**
   * Remember this selection. We (I, Daniel) make this public
   * to be able to test?
   */
  public nameSpace: string = 'retail'

  private morningstar: any = {} as any
  private sub$ = new Subscription()


  constructor(
    public customerService: CustomerService,
    public configService: ConfigService,
    private morningstarService: MorningstarService,
    private portfolioService: PortfolioService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {

  }

  private static normalizePhoneNumber(number: string): string {
    let res = number + ''

    res = res.replace(/\D/g, '')

    res = res.replace(/^4607/g, '467')

    while (res.startsWith('0')) {
      res = res.substring(1)
    }

    if (res.startsWith('46')) {
      return `+${res}`
    }

    if (res.length === 0) {
      return ''
    }
    return `+46${res}`
  }

  public ngOnInit(): void {
    this.configService.status$.next('Kollar hur Abasec mår...')
    let userName = ''
    const appConfig = JSON.parse(JSON.stringify(appConfigTpl))
    const loaderConfig = JSON.parse(JSON.stringify(loaderConfigTpl))
    const inputData = {}
    this.sub$ = this.configService.currentUser$.pipe(
      switchMap((user: SparbankenUser | null) => {
        userName = (user as SparbankenUser).name
        return this.customerService.checkTheFeelingsOfAbasec()
      }),
      switchMap(() => {
        this.configService.status$.next('Hämtar kundens konton...')
        return this.customerService.getAccounts()
      }),
      switchMap((accounts: MorningstarAccount[]) => {
        this.configService.status$.next('Hämtar sparad data från kund...')
        appConfig.components.mwcPortfolioPlanner.settings.clientAccounts = accounts
        return this.customerService.currentCustomer$
      }),
      filter(Boolean),
      first(), // Take one only, we do not want to reload on every customer change.
      switchMap((customer: Customer) => {
        appConfig.components.mwcPortfolioPlanner.settings.clientAccounts =
          appConfig.components.mwcPortfolioPlanner.settings.clientAccounts.concat(customer.morningstar.accounts || [])
        // We do this to make sure old legacy saves have its checked status removed.
        // either this or revisit all old accounts. // Daniel 2023-02-24
        appConfig.components.mwcPortfolioPlanner.settings.clientAccounts.forEach((a: MorningstarAccount) => delete a.checked)
        const answers = {
          questionnaireAnswers: customer.morningstar.answers || []
        }
        Object.assign(inputData, answers)
        const reportResults: MorningstarReportResult = {
          benchmark: null,
          reportInfo: {
            preparedBy: {preparedByAdvisorName1: userName},
            preparedFor: {
              preparedForEpostaddress: '',
              preparedForName: customer.firstName + ' ' + (customer.lastName ? customer.lastName : ''),
              preparedForPersonnummer: customer.sub,
              preparedForPhone: ''
            },
            reportHeader: {reportName: ''}
          },
          reportSettings: {},
          reports: [
            'coverPage',
            'coverPageExternalPdfs',
            'rtqReport',
            'riskProfile',
            'currentXrayV2Report',
            'proposedXrayV2Report',
            'portfolioComparisonReport',
            'assetAllocator',
            'costComparison',
            'advisorNotes',
            'kiidDocuments',
            'fundProfiles',
            'signOff',
            'externalPdfs'
          ]
        }
        Object.assign(inputData, {reportResults})
        // Set the initial name space
        this.nameSpace = customer.corporate ? NAMESPACE_CORPORATE : NAMESPACE_RETAIL
        loaderConfig.configuration.namespace = this.nameSpace
        this.morningstarService.namespace$.next(this.nameSpace)
        this.configService.status$.next('Hämtar modellportföljer...')
        return this.portfolioService.getPortfolios()
      }),
      switchMap((portfolios: ModelPortfolio[]) => {
        appConfig.components.mwcPortfolioPlanner.settings.modelPortfolios = portfolios
        this.configService.status$.next('Loggar in på Morningstar...')
        return this.morningstarService.getAccessToken()
      }),
      switchMap((res: MorningStartLoginResult) => {
        this.configService.status$.next('Nu väntar vi bara på att Morningstar skall starta...')
        loaderConfig.apiTokens.apiGatewayToken = res.accessToken
        return this.callMorningstar(loaderConfig, appConfig, inputData)
      })
    )
      .subscribe()
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
  }

  public printCallback = (e: any) => {
    this.customerService.currentCustomer$.pipe(
      filter(Boolean),
      first(),
      switchMap((customer: Customer) => {
        customer.morningstar.accounts = this.morningstarService.getAccounts().filter(a => !a.abasec)
        customer.morningstar.answers = this.morningstarService.getAnswers()
        const reportResults = this.morningstarService.getReportResults()
        customer.email = reportResults.reportInfo.preparedFor.preparedForEpostaddress
        customer.mobile = MorningstarComponent.normalizePhoneNumber(reportResults.reportInfo.preparedFor.preparedForPhone)
        return this.customerService.saveCustomer(customer)
      })
    ).subscribe({
      next: () => {
        this.morningstarService.sendForPdf(JSON.parse(e.detail.apiSettings.body))
      }
    })
  }

  /**
   * Heads up this (and next) is random shite using undocumented features
   * of Morningstôr.
   *
   * This one is for setting ?
   */
  public stepCallback = (e: CustomEvent) => {
    // Pure luck that this work. If steps move around, then change 7...
    if (e.detail[0].id === 7) {
      this.morningstarService.setHoldings()
    }

    /**
     * Another hack for M* to append some text where it does
     * not belong and cannot be fitted in their own framework
     */
    if (e.detail[0].id === 3) {
      setTimeout(() => {
        const node = this._document.createElement('div')
        let shite: Element
        if (this.nameSpace.indexOf('retail') !== -1) {
          shite = this._document.querySelectorAll('.mwc-pp-questionnaire__pp > section')[28]
          node.innerHTML += retailText
        } else {
          shite = this._document.querySelectorAll('.mwc-pp-questionnaire__pp > section')[25]
          node.innerHTML += corporateText
        }
        shite.appendChild(node)
      }, 1)
    }
  }

  public setStepCallBack(node: any): void {
    if (node) {
      node.addEventListener('step-changed', this.stepCallback)
    }
  }

  private callMorningstar(loaderConfig: any, appConfig: any, inputData: any): Observable<any> {
    loaderConfig.configuration.overrides = appConfig
    return from(morningstar.loader.load(loaderConfig)).pipe(
      tap(() => {
        this.morningstar = this.renderer.createElement('mwc-pp-portfolio-planner')
        // Not my fault that Morningstar requires this shite.
        setTimeout(() => {
          this.morningstar.inputData = inputData
          // Right place ?
          this.morningstarService.resetSelection()
        }, 1)
        this.morningstar.addEventListener('report-requested', this.printCallback)
        this.morningstarService.portfolioPlanner = this.morningstar
        this.renderer.setAttribute(this.morningstar, 'mwc-id', 'mwcPortfolioPlanner')
        this.renderer.appendChild(this.parent.nativeElement, this.morningstar)
        this.loading = false
        // Advanced cheating.
        this.setStepCallBack(document.querySelector('mwc-pp-workflow'))
      }))
  }
}
