import {inject} from '@angular/core'
import {ActivatedRouteSnapshot, Router} from '@angular/router'
import {Observable, of} from 'rxjs'
import {ConfigService} from '../services/config.service'
import {
  COUNSELLING_ROUTE_PATH,
  CUSTOMER_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  MORNINGSTAR_ROUTE_PATH,
  PORTFOLIO_ROUTE_PATH
} from './data-types'

export const authGuard = (route: ActivatedRouteSnapshot): Observable<boolean | Promise<boolean>> => {
  const currentRoute = route.url.toString()
  const router = inject(Router)
  const configService = inject(ConfigService)

  if (currentRoute === PORTFOLIO_ROUTE_PATH) {
    return of(configService.isPortfolioManager$() || configService.isIrUser$())
  }

  if (currentRoute === CUSTOMER_ROUTE_PATH) {
    return of(configService.isIrUser$() || configService.isIrAdmin$())
  }

  if ([MORNINGSTAR_ROUTE_PATH, COUNSELLING_ROUTE_PATH].includes(currentRoute)) {
    return of(configService.isIrUser$())
  }

  // Navigate to "login" screen cause there are not enough permissions
  router.navigate([LOGIN_ROUTE_PATH]).then()
  return of(false)
}
