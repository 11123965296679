<table [dataSource]="dataSource" class="mat-elevation-z2" mat-table matSort matSortStart="asc">
  <caption>List of portfolios</caption>

  <ng-container matColumnDef="name">
    <th *matHeaderCellDef id="name" mat-header-cell mat-sort-header="name">Namn</th>
    <td (click)="portfolioService.currentPortfolio$.next(portfolio)" *matCellDef="let portfolio"
        class="name-cell"
        mat-cell>{{ portfolio.name }}</td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th *matHeaderCellDef class="center" id="delete" mat-header-cell>Radera</th>
    <td (click)="delete(portfolio.id)" *matCellDef="let portfolio" class="center" mat-cell>
      <mat-icon class="table-icon" color="warn">delete_outline</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="copy">
    <th *matHeaderCellDef class="center" id="copy" mat-header-cell>Kopiera</th>
    <td (click)="copy(portfolio.id)" *matCellDef="let portfolio" class="center" mat-cell>
      <mat-icon class="table-icon" color="primary">content_copy</mat-icon>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr *matRowDef="let portfolio; columns: displayedColumns"
      class="portfolio-row"
      mat-row>

  <tr *matNoDataRow class="no-data">
    <td [attr.colspan]="displayedColumns.length" class="no-data">
      Inga portföljer här inte?
  </tr>
</table>
