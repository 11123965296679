import {inject, Injectable} from '@angular/core'
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../environments/environment'
import {switchMap, tap} from 'rxjs/operators'

export interface PortfolioHolding {
  name: string
  identifier: string
  identifierType: string

  /**
   * Weight, set by M* to percent as 10 is 10%
   */
  weight: number

  /**
   * This is known as Årlig avgift
   */
  ongoing: number

  /**
   * Transaction fee.
   */
  transaction: number

  /**
   * The distributor compensation in percent of the holing.
   */
  distributorCompensation: number

  // Called distributörsersättning, mapped from Fund AdministrationFee
  // It is the qouta between ongoing and distributorCompensation
  distributorCompensationPercentage: number

  amount?: number
}

export interface ModelPortfolio {
  id: string
  name: string
  holdings: PortfolioHolding[] | never[]
  version?: number
}

export interface Fund {
  id: string
  IsinCode: string
  AdministrationFee: number
}

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  public portfolios$ = new BehaviorSubject<ModelPortfolio[]>([])

  /**
   * This can be both read and published to ...
   */
  public currentPortfolio$ = new ReplaySubject<ModelPortfolio | null>(1)

  private readonly httpClient = inject(HttpClient)

  public getPortfolios(): Observable<ModelPortfolio[]> {
    const url = `${environment.apiUrl}/portfolios`
    return this.httpClient.get<ModelPortfolio[]>(url).pipe(
      tap((portfolios: ModelPortfolio[]) => {
        portfolios.sort((a: ModelPortfolio, b: ModelPortfolio) => a.name.localeCompare(b.name))
        this.portfolios$.next(portfolios)
      })
    )
  }

  public deletePortfolio(id: string): Observable<ModelPortfolio[]> {
    const url = `${environment.apiUrl}/portfolios/${id}`
    return this.httpClient.delete(url).pipe(
      switchMap(() => {
        return this.getPortfolios()
      })
    )
  }

  /**
   * Note that this is 'save' so we already have the portfolio.
   */
  public savePortfolio(portfolio: ModelPortfolio): Observable<ModelPortfolio> {
    const url = `${environment.apiUrl}/portfolios/${portfolio.id}`
    return this.httpClient.put<ModelPortfolio>(url, portfolio).pipe(
      tap((p: ModelPortfolio) => this.currentPortfolio$.next(p)))
  }

  public createPortfolio(portfolio: ModelPortfolio): Observable<ModelPortfolio> {
    const url = `${environment.apiUrl}/portfolios`
    return this.httpClient.put<ModelPortfolio>(url, portfolio).pipe(
      tap((p: ModelPortfolio) => this.currentPortfolio$.next(p)))
  }

  public importFile(data: string): Observable<any> {
    const url = `${environment.apiUrl}/portfolios`
    return this.httpClient.post(url, data).pipe(
      switchMap(() => {
        return this.getPortfolios()
      })
    )
  }
}
