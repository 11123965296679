import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http'
import {inject} from '@angular/core'
import {Observable} from 'rxjs'
import {ConfigService} from '../services/config.service'

/**
 * Intercept all requests and add auth token. We should however
 * do this only on white listed requests so that we do not
 * send our tokens when not needed. In its simplest form this
 * assumes that all outgoing requests after the token is set
 * should have it.
 */
export const authInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const configService = inject(ConfigService)
  const token = configService.accessToken$()
  if (!token) {
    return next(req)
  }

  // We cant send bearer when we collect documents from S3
  if (!req.url.includes('spb-ir-users')) {
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`)
    })
    return next(authReq)
  }
  /**
   * Else set no more headers
   */
  return next(req)
}
