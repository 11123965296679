import {Component, OnDestroy, OnInit} from '@angular/core'
import { FormArray, FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms'
import {Subscription} from 'rxjs'
import {debounceTime, filter, tap} from 'rxjs/operators'
import {ConfigService} from '../../services/config.service'
import {
  ModelPortfolio,
  PortfolioHolding,
  PortfolioService
} from '../../services/portfolio.service'
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'spb-portfolio',
    templateUrl: './portfolio.component.html',
    styleUrls: ['./portfolio.component.scss'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, MatFormField, MatLabel, MatInput, NgFor, MatIcon, MatButton, AsyncPipe]
})
export class PortfolioComponent implements OnInit, OnDestroy {

  public portfolioForm = new FormGroup({
    id: new FormControl<string>({
      disabled: true,
      value: ''
    }, {nonNullable: true}),
    name: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    holdings: new FormArray([]),
    totalWeight: new FormControl<number>(0, {
      nonNullable: true, validators:
        [Validators.required,
          Validators.max(100),
          Validators.min(100)]
    }),
    version: new FormControl<number>(0, {nonNullable: true})
  })

  private portfolio$: Subscription = new Subscription()

  constructor(
    public configService: ConfigService,
    public portfolioService: PortfolioService
  ) {
    this.addHolding({} as any)
  }

  get holdings(): FormArray {
    return this.portfolioForm.get('holdings') as FormArray
  }

  public ngOnInit(): void {
    this.portfolio$ = this.portfolioService.currentPortfolio$.pipe(
      tap(() => {
        this.portfolioForm.reset()
        this.holdings.clear()
      }),
      filter<ModelPortfolio | null>(Boolean)
    ).subscribe({
      next: (portfolio: ModelPortfolio | null) => {
        this.portfolioForm.patchValue(portfolio as any)
        portfolio!.holdings.forEach((holding: PortfolioHolding) => this.addHolding(holding))

        // If user logged is not a portfolio manager all fields will be disabled
        if (!this.configService.isPortfolioManager$()) {
          Object.keys(this.portfolioForm.controls).forEach(key => {
            this.portfolioForm.get(key)?.disable()
          })
        }
      }
    })

    this.holdings.valueChanges.pipe(
      debounceTime(30)
    ).subscribe({
      next: (holdings: PortfolioHolding[]) => {
        let sum = 0
        holdings.forEach((holding: PortfolioHolding) => sum += holding.weight)
        this.portfolioForm.get('totalWeight')!.setValue(sum)
      }
    })
  }

  public ngOnDestroy(): void {
    this.portfolio$.unsubscribe()
  }

  public addHolding(holding?: PortfolioHolding): void {

    holding = holding ?? {
      identifier: '',
      identifierType: '',
      name: '',
      weight: 0,
      ongoing: 0,
      transaction: 0,
      distributorCompensation: 0,
      distributorCompensationPercentage: 0
    }
    const formGroup = new FormGroup({
      name: new FormControl<string>(holding.name, Validators.required),
      identifier: new FormControl<string>(holding.identifier, Validators.required),
      identifierType: new FormControl<string>('ISIN', Validators.required),
      weight: new FormControl<number>(holding.weight,
        [Validators.required, Validators.min(0), Validators.max(100)]),
      ongoing:
        new FormControl<number>(holding.ongoing, {
          nonNullable: true,
          validators: [Validators.required, Validators.min(0), Validators.max(100)]
        }),
      transaction:
        new FormControl<number>(holding.transaction, {
          nonNullable: true,
          validators: [Validators.required, Validators.min(0), Validators.max(100)]
        }),
      distributorCompensation:
        new FormControl<number>(holding.distributorCompensation, {
          nonNullable: true,
          validators: [Validators.required, Validators.min(0), Validators.max(100)]
        }),
      // This field has to remain until we redo the compensation model.
      distributorCompensationPercentage:
        new FormControl<number>({
          disabled: true,
          value: holding.distributorCompensation
        }, {
          nonNullable: true
        })
    })
    formGroup.controls.distributorCompensation.valueChanges.subscribe({
      next: () => {
        formGroup.controls.distributorCompensationPercentage.setValue(
          formGroup.controls.distributorCompensation.value,
          {emitEvent: false})
      }
    })
    this.holdings.push(formGroup)
  }

  public save(): void {
    this.portfolioService.savePortfolio(this.portfolioForm.getRawValue()).subscribe({
      next: () => {
        this.portfolioService.currentPortfolio$.next(null)
        this.portfolioService.getPortfolios().subscribe()
      }
    })
  }

  public create(): void {
    this.portfolioService.createPortfolio(this.portfolioForm.getRawValue()).subscribe({
      next: () => {
        this.portfolioService.currentPortfolio$.next(null)
        this.portfolioService.getPortfolios().subscribe()
      }
    })
  }
}
