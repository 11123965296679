import {Component, inject} from '@angular/core'
import {ConfigService} from '../../services/config.service'
import {MatIconButton} from '@angular/material/button'
import {MatIcon} from '@angular/material/icon'
import {AsyncPipe} from '@angular/common'

@Component({
  selector: 'spb-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss'],
  standalone: true,
  imports: [MatIconButton, MatIcon, AsyncPipe]
})
export class ErrorDisplayComponent {
  public readonly configService = inject(ConfigService)
}
