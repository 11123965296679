<div *ngIf="portfolioService.currentPortfolio$ | async"
     class="holder mat-elevation-z8">
  <form [formGroup]="portfolioForm">
    <mat-form-field class="portfolio-field" floatLabel="always">
      <mat-label>Namn på portföljen</mat-label>
      <label>
        <input formControlName="name"
               matInput
               type="text">
      </label>
    </mat-form-field>
    <div formArrayName="holdings">
      <div
        *ngFor="let holding of holdings.controls;let i = index;let last = last"
        [formGroupName]="i"
        class="holding">
        <mat-form-field class="holding-name-field" floatLabel="always">
          <mat-label *ngIf="i === 0">Namn på innehav</mat-label>
          <label>
            <input formControlName="name"
                   matInput
                   type="text">
          </label>
        </mat-form-field>
        <mat-form-field class="isin-field">
          <mat-label *ngIf="i === 0">ISIN</mat-label>
          <label>
            <input formControlName="identifier"
                   matInput
                   type="text">
          </label>
        </mat-form-field>
        <mat-form-field class="weight-field">
          <mat-label *ngIf="i === 0">Vikt</mat-label>
          <label>
            <input formControlName="weight"
                   matInput
                   type="number">
          </label>
        </mat-form-field>
        <mat-form-field class="distributor-field">
          <mat-label *ngIf="i === 0">Årlig avgift</mat-label>
          <label>
            <input class="no-flippers"
                   formControlName="ongoing"
                   matInput
                   type="number">
          </label>
        </mat-form-field>
        <mat-form-field class="distributor-field">
          <mat-label *ngIf="i === 0">Trans. avgift</mat-label>
          <label>
            <input class="no-flippers"
                   formControlName="transaction"
                   matInput
                   type="number">
          </label>
        </mat-form-field>
        <mat-form-field class="distributor-field">
          <mat-label *ngIf="i === 0">Distr. ers.</mat-label>
          <label>
            <input class="no-flippers"
                   formControlName="distributorCompensation"
                   matInput
                   type="number">
          </label>
        </mat-form-field>

        <!-- Only Portfolio Managers can edit portfolios -->
        @if (configService.isPortfolioManager$()) {
          <mat-icon (click)="holdings.removeAt(i)"
                    class="icon-field clickable"
                    color="warn">delete_outline
          </mat-icon>
        }
      </div>
    </div>
    <div class="holding-actions">
      <!-- Only Portfolio Managers can edit portfolios -->
      @if (configService.isPortfolioManager$()) {
        <button
          (click)="addHolding()"
          class="holding-button"
          mat-raised-button>Lägg till innehav
        </button>
      }
      <mat-form-field class="total-field">
        <mat-label>Totalt</mat-label>
        <input formControlName="totalWeight" matInput>
      </mat-form-field>
    </div>
    <div class="form-actions">
      <button (click)="portfolioService.currentPortfolio$.next(null)"
              color="warn"
              mat-stroked-button
              type="reset">Avbryt
      </button>

      <!-- Only Portfolio Managers can create/update portfolios -->
      @if (configService.isPortfolioManager$()) {
        <button (click)="save()"
                *ngIf="portfolioForm.controls.id.value; else createButton"
                [disabled]="!portfolioForm.valid || !portfolioForm.dirty"
                color="primary"
                mat-raised-button
                type="button">Spara
        </button>
      }
    </div>
    <ng-template #createButton>
      <button (click)="create()"
              [disabled]="!portfolioForm.valid || !portfolioForm.dirty"
              color="primary"
              mat-raised-button
              type="button">Spara
      </button>
    </ng-template>
  </form>
</div>
