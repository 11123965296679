import {Component} from '@angular/core'
import {ConfigService} from '../../services/config.service'
import {ModelPortfolio, PortfolioService} from '../../services/portfolio.service'
import { MatButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { PortfolioListComponent } from '../portfolio-list/portfolio-list.component';
import { PortfolioComponent } from '../portfolio/portfolio.component';

@Component({
    selector: 'spb-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [MatButton, RouterLink, MatIcon, PortfolioListComponent, PortfolioComponent]
})
export class HomeComponent {

  public template: ModelPortfolio = {
    holdings: [
      {
        weight: 100,
        identifierType: 'ISIN',
        name: '',
        identifier: '',
        ongoing: 0,
        transaction: 0,
        distributorCompensation: 0,
        distributorCompensationPercentage: 0
      }
    ],
    id: '',
    name: ''
  }

  constructor(
    public configService: ConfigService,
    public portfolioService: PortfolioService
  ) {
  }
}
