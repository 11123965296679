@if (environment.test) {
  <div>
    <spb-test-pdf></spb-test-pdf>
  </div>
}
<div class="top-holder">
  <spb-namespace></spb-namespace>
  <spb-error-display></spb-error-display>
  <spb-customer-action></spb-customer-action>
</div>
<spb-morningstar></spb-morningstar>
