<div *ngIf="customerService.currentCustomer$ | async as customer; else load">
  @if (loading) {
    <div class="loader">
      @if (configService.status$ | async;as message) {
        <p>{{ message }}</p>
      }
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  }
</div>
<ng-template #load>
  <p>Du har inte valt någon kund, klicka <a [routerLink]="['/']">här</a> för att välja en.</p>
</ng-template>
<div #planner [ngClass]="{ loading: 'hidden'}"></div>
